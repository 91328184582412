export enum OrderBy {
    CHRONOLOGICAL_ORDER = 0,
    KANA_ORDER = 1,
    ARRIVAL_TIME_ORDER = 2,
    TRAVEL_TIME_ORDER = 3,
    SLOT_ORDER = 4,
}

export enum CollectionStatus {
    UNCOLLECTED = 0,
    COLLECTED_OR_EXCEPT = 1,
    ALL = 3,
}
